import { ComponentFactoryResolver, ComponentRef, Inject, Injectable, Type, } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface CustomDropdownComponent<EventResponseType, DataType> {
  position: {left: number; top: number };
  onSubmit: () => Observable<EventResponseType>;
  data: DataType;
  response: EventResponseType;
  ref: ComponentRef<CustomDropdownComponent<any, any>>;
}

@Injectable({providedIn: 'root'})
export class DropdownService {

  rootViewContainer;
  currentComponent: ComponentRef<CustomDropdownComponent<any, any>>;

  constructor(@Inject(ComponentFactoryResolver) private factoryResolver) {}

  setRootViewContainerRef(viewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }

  openDropdown<T extends CustomDropdownComponent<EventResponseType, DataType>, EventResponseType = T['response'], DataType = T['data']>(component: Type<T>, data: DataType, position: { left: number; top: number }): Observable<EventResponseType> {
    if (this.currentComponent) {
      this.currentComponent.destroy();
    }
    const factory = this.factoryResolver.resolveComponentFactory(component);
    const newComponent: ComponentRef<T> = factory.create(this.rootViewContainer.parentInjector);
    newComponent.instance.position = position;
    newComponent.instance.data = data;
    newComponent.instance.ref = newComponent;
    this.currentComponent = newComponent;
    this.rootViewContainer.insert(this.currentComponent.hostView);
    return this.currentComponent.instance.onSubmit().pipe(
      tap(() => this.currentComponent.destroy())
    );
  }
}
